@use '../../../assets/scss/utilities' as *;

.add-existing-user-form {
  width: 399px;

  .registered-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    width: 100%;
    height: 56px;
    background: $white2;
    border-radius: 4px;
    position: relative;
    left: 0px;
    top: 4px;
    & > .MuiAvatar-root {
    }

    .user-name {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.75px;
      color: $pure_black;
      margin: 0px 12px;
    }
  }

  @include mq(448px, max) {
    form {
      .form-control {
        & > div {
          display: flex;
          justify-content: center;
          & .field {
            width: 69%;
          }
        }
      }
    }
  }
  @include mq(324px, max) {
    form {
      .form-control {
        & > div {
          flex-direction: column;
          padding-left: 91px;
          & .field {
            width: 68%;
          }
        }
      }
      .modal-actions {
        flex-direction: column;
        & button {
          width: 31% !important;
        }
      }
    }
  }
}
