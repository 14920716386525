@use '../../../../assets/scss/utilities' as *;

.progress-item {
  .subject-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subject {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 5px;

      img {
        width: 38px;
        height: 38px;
      }
      .subject-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $darker_blue2;
      }
    }
    .progression {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $purple;
    }
  }
  .MuiLinearProgress-root {
    background: $lighter_blue14;
    border-radius: 6px;
    width: 100%;
    height: 8px;
    .MuiLinearProgress-bar {
      background-color: $lighter_red5;
    }
  }
}
