@use '../../../assets/scss/utilities' as *;

.progression-modal {
  box-shadow: none !important;

  .card-header {
    padding: 5.5px 0 0 1px;
    .MuiTypography-root {
      font-weight: 500 !important;
    }
  }

  .user-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $pure_black;
    height: 40px;
  }
  .MuiDialogContent-root {
    padding: 0px 81px 0px 81px;
    max-height: 319px;
    .modal-content {
      .subjects {
        hr {
          width: 100%;
          padding: 7px;
          background: none;
          margin-bottom: 10px;
        }
      }
    }

    @include mq(450px, max) {
      padding: 0;
    }
  }
  .modal-actions {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > button {
      padding: 0;
      width: 21%;
      height: 32px;
      border: 2px solid;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 0.75px;
      @include mq(450px, max) {
        width: 40% !important;
      }
      &:hover {
        background: $lighter_blue4 !important;
      }
    }
  }
}
