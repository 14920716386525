@use '../../assets/scss/utilities' as *;

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none !important;
  padding-bottom: 0 !important;
  background: $lighter_blue1;
  & > .MuiCircularProgress-colorPrimary {
    color: $summer_sky;
  }
}
