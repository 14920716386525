@use '../../../assets/scss/utilities' as *;

.add-new-user-form {
  & > form {
    .user-name {
      display: flex;
      gap: 4px;
      padding-top: 7px;
      & .field {
        width: 198px !important;
      }
    }
    .form-control {
      & .field,
      & .select {
        height: 43px;
        background: $lignter_gray3;
        margin: 2px 0px !important;
      }
    }
    & .modal-actions {
      margin: 22px 0 32px 0 !important;
    }
    @include mq(448px, max) {
      .user-name {
        display: block;
        .field {
          width: 100% !important;
          height: 32px;
          & > input {
            height: 27px;
          }
        }
      }
      .form-control {
        .field {
          width: 100%;
          height: 32px;
          & > input {
            height: 27px;
          }
        }
      }

      .form-control {
        & > .select-container {
          .select {
            height: 27px;
          }
        }
      }
    }
    @include mq(328px, max) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .user-name {
        .form-control {
          display: flex;
          justify-content: center;
        }
      }

      .form-control {
        & > .select-container {
          .select {
            height: 27px;
            width: 100%;
          }
        }
      }
      .modal-actions {
        flex-direction: column;
      }
    }
    @include mq(324px, max) {
      .form-control {
        & > .select-container {
          .select {
          }
        }
      }
    }
  }
}
.css-1vp9nu5-control,
.css-1vp9nu5-control:hover {
  border-color: $summer_sky !important;
  box-shadow: 0 0 1px $summer_sky !important;
  border: 2px solid $summer_sky !important;
}
