@use '../../assets/scss/utilities' as *;

.view-title {
  color: $summer_sky;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;

  @include mq(1570px, max) {
    width: 62% !important;
  }
  @include mq(780px, max) {
    font-size: 24px;
  }

  &.orange {
    color: $darker_orange;
  }
  &.widh-breadcrumbs {
    margin-bottom: 0 !important;
  }
}

.MuiBreadcrumbs-root {
  margin-bottom: 20px !important;
  & > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li {
    & > a {
      color: $lighter_blue12;
      font-size: 18px;
      font-weight: 500;
      transition: all 300ms ease;
      &:hover {
        text-decoration: underline;
      }
    }
    & > p {
      color: $lighter_blue9;
      font-size: 18px;
    }
  }
}
