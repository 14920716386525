@use '../assets/scss/utilities' as *;

@import './Loading/loading';
@import './Logo/logo';
@import './MenuDropdown/menuDropdown';
@import './ActionLink/actionLink';
@import './Button/taButton';
@import './Modals/modals';
@import './ViewTitle/viewTitle';

//Blue Button
.blueButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: 25% !important;
  }
  @media screen and (max-width: 350px) {
    width: 15% !important;
  }
  .buttonText {
    @include buttonText();
  }
}

.modalLine {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $summer_sky;
}

.link {
  text-decoration: none;
  color: $pure_black;
  transition: all 300ms ease;
  &.primary {
    color: $summer_sky;
    &:hover {
      color: $darker_blue1;
    }
  }
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
