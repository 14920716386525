@use '../assets/scss/utilities' as *;

.ta-row {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
  .flex-container {
    display: flex;
    justify-content: center;
    .simple-title {
      color: $summer_sky;
      font-weight: bold !important;
      font-size: 16px !important;
    }
    .left {
      min-width: 67%;
      margin: 5px;
      box-shadow: 0px 3px 10px $darker_blue6;
      border-radius: 10px;
      @include mq(1024px) {
        min-width: 60%;
      }
    }
    .right {
      min-width: 30%;
      margin: 5px;
      box-shadow: 0px 3px 10px $darker_blue6;
      border-radius: 10px;
      @include mq(1024px) {
        min-width: 20%;
      }
    }
  }

  .ta-column {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 12px;
    padding-right: 16px;
    padding-left: 0;
    @include mq(1200px, max) {
      width: 100%;
    }
    @include mq(948px, max) {
      padding-right: 0 !important;
    }
    &.right-margin {
      margin-right: 236px;
      @include mq(948px, max) {
        margin-right: 0;
      }
    }
    &.fixed {
      width: 240px;
      position: fixed;
      top: 68px;
      padding-right: 4px;
      @include mq(1200px, max) {
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
        display: none;
      }
      & > :not(:first-child) {
        margin-top: 16px;
      }
      &.right {
        right: 20px;
      }
    }
    & > .child-left,
    .child-right,
    .child-full {
      margin-bottom: 16px;
      & > :not(:first-child) {
        margin-top: 16px;
      }
    }
    & > .child-left {
      box-sizing: border-box;
      width: 70%;
      margin-right: 16px;
      @include mq(948px, max) {
        width: 100% !important;
        margin-right: 0;
      }
    }
    & > .child-right {
      box-sizing: border-box;
      width: calc(30% - 16px);
      @include mq(948px, max) {
        width: 100% !important;
      }
    }
    & > .child-full {
      box-sizing: border-box;
      width: 100%;
    }
    &.childrens-inverse {
      flex-direction: row-reverse;
      & > .child-left {
        margin-right: 0;
      }
      & > .child-right {
        margin-right: 16px;
        @include mq(948px, max) {
          margin-right: 0;
        }
      }
    }
    &.layout-1 {
      & > .child-left {
        width: 70%;
      }
      & > .child-right {
        width: calc(30% - 16px);
      }
    }
    &.layout-2 {
      & > .child-left {
        width: 60%;
        @include mq(1380px, max) {
          width: calc(50% - 8px);
        }
      }
      & > .child-right {
        width: calc(40% - 16px);
        @include mq(1380px, max) {
          width: calc(50% - 8px);
        }
      }
    }
  }
}

.full-page {
  max-width: 1592px;
  margin: 0 auto;
}

.ta-toolbar {
  min-height: 44px !important;
}
