@use '../../../assets/scss/utilities' as *;

.history-card {
  box-shadow: 0px 2px 4px rgba(179, 208, 232, 0.6) !important;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 40px 0;
  .history-card-icon {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      color: $pure_white;
      font-size: 30px;
    }
  }
  .history-card-status {
    font-weight: 500;
    font-size: 15px;
  }
  .history-card-description {
    font-size: 13px;
    color: $lighter_blue10;
    display: flex;
    padding: 0 15px;
    .users-number {
      font-size: 16px;
      color: $pure_black;
      font-weight: 800;
      margin-top: -1px;
    }
  }
  .history-card-btn {
    & > button {
      all: unset;
      padding: 4px 12px;
      width: 80%;
      //100px;
      height: 28px;
      border-radius: 6px;
      flex-grow: 0;
      margin: 4px 0px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: $pure_white;
      cursor: pointer;
    }
  }
}

.success-card {
  background: rgba(221, 243, 231, 0.929);

  .history-card-icon {
    background: $light_green;
  }
  .history-card-status {
    color: $light_green;
  }

  .history-card-btn {
    & > button {
      background: linear-gradient(101.94deg, $lighter_green1 0%, $light_green 100%);
    }
  }
}
.error-card {
  background: $lighter_red6;

  .history-card-icon {
    background: $darker_red1;
  }
  .history-card-status {
    color: $darker_red1;
  }
  .history-card-description {
  }

  .history-card-btn {
    & > button {
      background: linear-gradient(101.94deg, $lighter_red4 0%, $darker_red1 100%);
    }
  }
}

.no-seats-card {
  .history-card-description {
    padding-bottom: 37px;
  }
}
