@use '../../../assets/scss/utilities' as *;
.offer-item {
  display: flex;
  align-items: center;
  padding: 0px;
  width: 100%;
  padding: 4px;
  height: 45px;
  border-radius: 8px;
  cursor: pointer;

  & > img {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    margin: 0px 8px 0px 4px;
    background-color: $lighter_sky_blue9;
  }
  & > div {
    width: 100%;
    height: 19px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $darker_blue2;
    white-space: nowrap;
  }
}
.selected-offer {
  background: $lighter_sky_blue7 !important;
}
