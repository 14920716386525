@use '../../assets/scss/utilities' as *;

.ta-btn {
  border-radius: 0 !important;
  text-transform: none !important;
  background: $pure_white;
  height: 32px !important;
  transition: all 300ms ease;
  cursor: pointer;
  /* rounded */
  &.btn-rounded {
    border-radius: 4px !important;
  }
  &.onlyIcon {
    & > .MuiButton-label > .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.dark {
    background: $darker_blue2;
    color: $pure_white;
    &:hover {
      background: $darker_blue5;
    }
    &.Mui-disabled {
      color: rgb(255 255 255 / 40%);
      background: rgb(24 105 141 / 60%);
    }
  }
  &.primary {
    background: $summer_sky;
    color: $pure_white;
    &:hover {
      background: $sky_blue;
    }
  }
  &.grey {
    background: $pure_white;
    color: $summer_sky;
    &:hover {
      background: $lighter_blue4;
    }
  }

  &.success {
    background: $lighter_green2;
    color: $pure_white;
    &:hover {
      background: $lighter_green;
    }
  }

  &.warning {
    background: $lighter_yellow1;
  }
  &.danger {
    background: $lighter_red3;
    color: $pure_white;
    &:hover {
      background: $lighter_red1;
    }
  }
  &.navigation {
    background: $pure_white;
    color: $lighter_red3;
    &:hover {
      background: $lighter_blue4;
    }
  }

  &.MuiButton-outlined {
    background: transparent;
    border: 1px solid;
    border-color: $lighter_blue6;
    color: $darker_gray7;
    &.dark {
      border-color: $darker_blue2;
      color: $pure_white;
    }
    &.primary {
      border-color: $summer_sky;
      color: $summer_sky;
    }
    &.success {
      border-color: $lighter_green2;
      color: $lighter_green2;
    }
    &.warning {
      border-color: $lighter_yellow1;
      color: $lighter_yellow1;
    }
    &.danger {
      border-color: $lighter_red3;
      color: $lighter_red3;
      &:hover {
        background: $darken_red;
      }
    }

    & > .MuiButton-label > span > .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
      font-size: 14px !important;
    }
  }

  &.Mui-disabled {
    &.primary {
      background: $lighter_red7;
      color: $darker_gray2;
      opacity: 0.8;
    }
  }

  &.btn-lg {
    height: 40px !important;
    padding: 6px 32px;
  }
  &.white {
    height: 40px !important;
    background: $pure_white;
    width: auto;
    box-shadow: none;
    border: 1px solid $darker_gray;
    color: rgb(131, 130, 130) !important;
    margin-bottom: 10px;
    &:hover {
      background: $darker_gray;
      box-shadow: none;
      color: white !important;
    }
  }
}

.mt-12 {
  margin-top: 12px !important;
  margin-left: 10px !important;
}
