@use '../../assets/scss/utilities' as *;

.BaseBadge-root {
  cursor: pointer;
  & > img {
    filter: invert(31%) sepia(72%) saturate(567%) hue-rotate(153deg) brightness(93%) contrast(88%);
  }
  & > span {
    background-color: $dark_pink !important;
  }
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;
  & > img {
    filter: invert(31%) sepia(72%) saturate(567%) hue-rotate(153deg) brightness(93%) contrast(88%);
    height: 36px;
    cursor: pointer;
  }
  .user-balance {
    & > div:nth-child(1) {
      color: $lignter_gray1;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
    }
    & > div:nth-child(2) {
      color: $darker_blue2;
      font-size: 18px;
      font-weight: 600;
      line-height: 14px;
    }
  }
  @include mq(435px, max) {
    display: none;
  }
}
