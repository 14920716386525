@use '../../assets/scss/utilities' as *;

.select {
  .css-1t8y0t9-control {
    width: 100%;
    height: 44px;
    background: $lignter_gray3;
    border: 1px solid $lignter_gray1;
    border-radius: 4px;
    padding: 0px 10px 0 0px;
    margin-top: 4px;
    max-height: 200px;
    overflow-x: hidden;
    outline: none;

    &:focus {
      border: 1px solid $summer_sky;
    }

    .css-319lph-ValueContainer {
      padding: 0 !important;
      padding: 2px 1px !important;
    }
    .css-14el2xx-placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.75px;
      color: $lignter_gray1;
    }
  }
}

.error-select {
  .css-1t8y0t9-control {
    border: 2px solid $darker_red !important;
  }
}

.select {
  .css-b62m3t-container {
    .css-1t8y0t9-control {
    }
  }
}
