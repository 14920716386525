.add-points-modal {
  .add-points-modal-title {
    box-shadow: none !important;
    & > .MuiCardContent-root {
      padding-bottom: 13px !important;
      & > .card-header {
        & > .MuiTypography-root {
          margin-left: 5px !important;
          font-weight: 500 !important;
        }
      }
      & > .add-points-form {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }
    }
  }

  form {
    .modal-actions {
      display: flex;
      justify-content: flex-end;
      & > button {
        height: 37px;
        letter-spacing: 0.5px !important;
        border: none !important;
        width: 140px;
        position: relative;
        left: 8px;
        margin-top: 2px;
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
