@use '../../assets/scss/utilities' as *;

.MuiAppBar-positionFixed {
  & > .MuiToolbar-root {
    display: flex;
    gap: 36px;
    .MuiAutocomplete-root {
      .MuiInputBase-root {
        padding: 0;
        padding-right: 24px;
        & > .MuiInputBase-input {
          margin-left: 42px;
          min-width: fit-content;
          @include mq(713px, max) {
            min-width: auto;
          }
        }
        & > .MuiOutlinedInput-notchedOutline {
          border: none;
          outline: none;
        }
      }
    }
  }
}
.autocopmlete-item {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-bottom: 1px solid $lighter_blue8;
  width: 100%;
  transition: all 300ms ease;
  text-decoration: none;
  color: $pure_black;
  & > .title {
    font-size: 14px;
    color: $pure_black;
  }
  & > .subtitle {
    font-size: 12px;
    color: $lighter_blue11;
    transition: all 300ms ease;
  }
  &:hover > .title {
    color: $darker_blue3;
  }
}

.searchbar {
  & > .MuiFormControl-root {
    & > .MuiInputBase-root {
      padding-right: 0 !important;

      & > .MuiAutocomplete-endAdornment {
        display: none;
      }
    }
  }
}
